<template>
  <form
    novalidate
    @submit.prevent="submit"
  >
    <calculator
      :on-campaign-page="onCampaignPage"
      @interacted="next"
    />
  </form>
</template>

<script>
  import { mapActions, mapGetters } from 'vuex'
  import Calculator from '../_shared/Calculator'

  export default {
    name: 'Step1SE',
    components: {
      Calculator
    },
    props: {
      onCampaignPage: {
        type: Boolean,
        required: false,
        default: false
      }
    },
    computed: {
      ...mapGetters('cl/formSteps', ['currentStep'])
    },
    async mounted () {
      await this.viewTracking({ label: 'loanDetails' })

      this.$emit('loaded')
    },
    methods: {
      ...mapActions('cl/analytics', ['viewTracking']),
      next () {
        if (this.currentStep === 1) this.submit()
      },
      submit () {
        this.$emit('submit')
      }
    }
  }
</script>
