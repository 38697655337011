var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      key: "steps",
      class: [
        "steps",
        {
          "lead-application lead-application--continue-application":
            _vm.isContinueApplicationLead,
          "lead-application lead-application--add-co-applicant":
            _vm.isAddCoApplicantLead,
        },
      ],
    },
    _vm._l(_vm.steps, function (step, index) {
      return _c(
        "div",
        {
          key: index,
          class: [
            "step",
            {
              "step--border": _vm.showStep(index) && step.border,
            },
          ],
          attrs: { id: step.name },
        },
        [
          _c(
            "zg-transition-block",
            { on: { "after-transition": _vm.changedStepAction } },
            [
              _vm.showStep(index)
                ? _c(_vm.stepComponent(index), {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: !_vm.stepValues(index).hidden,
                        expression: "!stepValues(index).hidden",
                      },
                    ],
                    key: _vm.stepComponent(index),
                    tag: "component",
                    attrs: {
                      "current-step": _vm.currentStep,
                      "current-step-name": _vm.currentStepName,
                      "on-beige-background": _vm.onBeigeBackground,
                      "on-campaign-page": _vm.onCampaignPage,
                    },
                    on: {
                      loaded: function ($event) {
                        return _vm.loaded("step")
                      },
                      submit: _vm.goToNextStep,
                    },
                  })
                : _vm._e(),
            ],
            1
          ),
        ],
        1
      )
    }),
    0
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }